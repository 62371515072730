$retina_2x: (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi);

@function sprite-url($map, $size: 1) {
  $url: nth(map-get($map, "urls"), $size);

  @return #{"url('#{$url}')"};
}

@function sprite-width($map) {
  @return map-get($map, "width");
}

@function sprite-height($map) {
  @return map-get($map, "height");
}

@function sprite-items($map) {
  @return map-get($map, "items");
}

@function sprite-icon-prop($map, $image, $prop) {
  $items: sprite-items($map);

  @if (map_has_key($items, $image)) {
    @return map-get(map-get($items, $image), $prop);
  }

 @error "#{$image} does not exist in #{sprite-id($map)}\a Available images are: #{sprite-names($map)}";
}

@function sprite-position($map, $image, $offset-x: 0, $offset-y: 0) {
  @return #{sprite-icon-prop($map, $image, "x") + $offset-x} #{sprite-icon-prop($map, $image, "y") + $offset-y};
}

@mixin retina-sprite($map, $image, $offset-x: 0, $offset-y: 0) {
  background-image: sprite-url($map);
  background-size: sprite-width($map) sprite-height($map);
  background-position: sprite-position($map, $image, $offset-x, $offset-y);

  @media #{$retina_2x} {
    background-image: sprite-url($map, 2);
  }
}
