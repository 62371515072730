@import "seatmaps";
@import "sprites";


$row_start: 32px;
$row_offset: 6px;
$seat_size: 20px;
$max_rows: 17;
// $departsHillCity: 1;
// $departsKeystone: 2;


#seat-picker-modal { // sass-lint:disable-line no-ids
  padding: 0.25em 1em;

  .seatcount {
    font-size: 0.8em;
  }

  .modal-title {
    font-size: 2em;
    line-height: 2.1em;
    margin: 0.1em;
    padding: 0;
  }

  .fill-seats {
    padding: 3px 1em 3px;
  }

  .legend {
    @include retina-sprite($seatmaps, "train-legend");
    width: 90px;
    height: 72px;
  }

  .train-carlist-container {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0.3em;
    margin: 0.5em 0;

    .train-carlists {
      white-space: nowrap;
      overflow-x: auto;
      padding-bottom: 1em;
      scrollbar-width: auto; /* firefox */
      scrollbar-color: #aaa #ccc; /* firefox */

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }
    }


    .train-description-out {
      position: absolute;
      top: 0px;
      left: 0.3em;
      z-index: 1999;
    }

    .train-description-return {
      position: absolute;
      top: 196px; /* sprite is 121, border is 6, and line height plus padding */
      left: 0.3em;
      z-index: 1999;
    }


    ul.carlist {
      margin: 2em 0 0 0;
      padding: 0;
      border-top: 6px solid #963620;
      display: inline-block;

      li.car {
        content: "";
        display: inline-block;
        position: relative;
        background-color: #dedede;
        margin: 2em 0 0 0;

        .car-name {
          font-size: 1.1em;
          margin: -1.5em auto 0 auto;
          text-align: center;
        }

        .seatmap {
          .seat {
            position: absolute;
            width: 20px;
            height: 20px;

            @for $i from 1 through $max_rows {
              &.row-#{$i} {
                left: calc(#{$row_start} + (#{$i} * (#{$row_offset} + #{$seat_size})));
              }
            }

            &.col-a {
              top: 91px;
            }
            &.col-b {
              top: 71px;
            }
            &.col-c {
              top: 41px;
            }
            &.col-d {
              top: 21px;
            }
          }
        }
      }

      @for $station_id from 1 through 2 {
        &.departure-station-#{$station_id} {
          li.car {
            &.rows-10 {
              @include retina-sprite($seatmaps, "car-#{$station_id}-10");
              width: 350px;
              height: 121px;
            }
            &.rows-10hhe {
              @include retina-sprite($seatmaps, "car-#{$station_id}-10hhe");
              width: 350px;
              height: 121px;
            }
            &.rows-12 {
              @include retina-sprite($seatmaps, "car-#{$station_id}-12");
              width: 405px;
              height: 121px;
            }
            &.rows-12eg {
              @include retina-sprite($seatmaps, "car-#{$station_id}-12eg");
              width: 405px;
              height: 121px;
            }
            &.rows-13 {
              @include retina-sprite($seatmaps, "car-#{$station_id}-13");
              width: 435px;
              height: 121px;
            }
            &.rows-13bb {
              @include retina-sprite($seatmaps, "car-#{$station_id}-13bb");
              width: 435px;
              height: 121px;
            }

            &.rows-14 {
              @include retina-sprite($seatmaps, "car-#{$station_id}-14");
              width: 465px;
              height: 121px;
            }
            &.rows-15 {
              @include retina-sprite($seatmaps, "car-#{$station_id}-15");
              width: 496px;
              height: 121px;
            }
            &.rows-15bc {
              @include retina-sprite($seatmaps, "car-#{$station_id}-15bc");
              width: 496px;
              height: 121px;
            }
            &.rows-15my {
              @include retina-sprite($seatmaps, "car-#{$station_id}-15my");
              width: 496px;
              height: 121px;
            }
            &.rows-16 {
              @include retina-sprite($seatmaps, "car-#{$station_id}-16");
              width: 514px;
              height: 121px;
            }
            &.rows-16hc {
              @include retina-sprite($seatmaps, "car-#{$station_id}-16hc");
              width: 496px;
              height: 121px;
            }
            &.rows-17rf {
              @include retina-sprite($seatmaps, "car-#{$station_id}-17rf");
              width: 540px;
              height: 121px;
            }
          }
        }
      }

      &.departure-station-1 {
        li.car {
          .seatmap {
            span.seat {
              &.col-b.unavailable,
              &.col-d.unavailable {
                @include retina-sprite($seatmaps, "BenchStatesOutbound", -40, 0);
                width: 20px;
                height: 20px;
              }
              &.col-a.unavailable,
              &.col-c.unavailable {
                @include retina-sprite($seatmaps, "BenchStatesOutbound", -40, -20);
                width: 20px;
                height: 20px;
              }
            }

            a.seat {
              &.col-b.selected,
              &.col-d.selected {
                @include retina-sprite($seatmaps, "BenchStatesOutbound", -20, 0);
                width: 20px;
                height: 20px;
              }
              &.col-b:hover,
              &.col-d:hover {
                @include retina-sprite($seatmaps, "BenchStatesOutbound", 0, 0);
                width: 20px;
                height: 20px;
              }
              &.col-a.selected,
              &.col-c.selected {
                @include retina-sprite($seatmaps, "BenchStatesOutbound", -20, -20);
                width: 20px;
                height: 20px;
              }
              &.col-a:hover,
              &.col-c:hover {
                @include retina-sprite($seatmaps, "BenchStatesOutbound", 0, -20);
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }

      &.departure-station-2 {
        li.car {
          .seatmap {
            span.seat {
              &.col-b.unavailable,
              &.col-d.unavailable {
                @include retina-sprite($seatmaps, "BenchStatesReturn", 0, 0);
                width: 20px;
                height: 20px;
              }
              &.col-a.unavailable,
              &.col-c.unavailable {
                @include retina-sprite($seatmaps, "BenchStatesReturn", 0, -20);
                width: 20px;
                height: 20px;
              }
            }

            a.seat {
              &.col-b.selected,
              &.col-d.selected {
                @include retina-sprite($seatmaps, "BenchStatesReturn", -20, 0);
                width: 20px;
                height: 20px;
              }
              &.col-b:hover,
              &.col-d:hover {
                @include retina-sprite($seatmaps, "BenchStatesReturn", -40, 0);
                width: 20px;
                height: 20px;
              }
              &.col-a.selected,
              &.col-c.selected {
                @include retina-sprite($seatmaps, "BenchStatesReturn", -20, -20);
                width: 20px;
                height: 20px;
              }
              &.col-a:hover,
              &.col-c:hover {
                @include retina-sprite($seatmaps, "BenchStatesReturn", -40, -20);
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }

      /* handle the handicapped icon positioning */
      @for $station_id from 1 through 2 {
        &.departure-station-#{$station_id} {
          li.car {
            div.seatmap {
              .seat {
                span.handicappedSeat {
                  position: absolute;
                  font-weight: 600;
                  font-size: 12px; /* use pixels, not em. */
                  line-height: 12px;
                  color: #3366FF;
                }

                &.col-a,
                &.col-c {
                  span.handicappedSeat {
                    top: 2px;
                  }
                }

                &.col-b,
                &.col-d {
                  span.handicappedSeat {
                    top: 7px;
                  }
                }

                &.selected {
                  span.handicappedSeat {
                    color: #AACCFF;
                  }
                }

                &:hover {
                  span.handicappedSeat {
                    color: #CCCCCC;
                  }
                }
              }
            }
          }
        }
      }
      &.departure-station-1 {
        li.car {
          div.seatmap {
            .seat {
              span.handicappedSeat {
                left: 3px;
              }
            }
          }
        }
      }
      &.departure-station-2 {
        li.car {
          div.seatmap {
            .seat {
              span.handicappedSeat {
                left: 8px;
              }
            }
          }
        }
      }


      /* hack to fix edward gillette facing seats in rows 5-8 */
      @for $station_id from 1 through 2 {
        &.departure-station-#{$station_id} {
          li.car.rows-12eg {
            div.seatmap {

              /* noteIcon overlay */
              .seat {
                span.noteIcon {
                  position: absolute;
                  font-weight: 300;
                  font-size: 12px; /* use pixels, not em. */
                  line-height: 12px;
                  color: #999999;
                }

                &.col-a,
                &.col-c {
                  span.noteIcon {
                    top: 2px;
                  }
                }

                &.col-b,
                &.col-d {
                  span.noteIcon {
                    top: 7px;
                  }
                }

                &.selected {
                  span.noteIcon {
                    color: #AAAAAA;
                  }
                }

                &:hover {
                  span.noteIcon {
                    color: #CCCCCC;
                  }
                }
              }

              .seat.row-5,
              .seat.row-7 {
                span.noteIcon {
                  left: 7px;
                }
              }
              .seat.row-6,
              .seat.row-8 {
                span.noteIcon {
                  left: 3px;
                }
              }

              /* seat selection states */
              span.seat.row-5,
              span.seat.row-7 {
                &.col-b.unavailable,
                &.col-d.unavailable {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", 0, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-a.unavailable,
                &.col-c.unavailable {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", 0, -20);
                  width: 20px;
                  height: 20px;
                }
              }

              a.seat.row-5,
              a.seat.row-7 {
                &.col-b.selected,
                &.col-d.selected {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", -20, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-b:hover,
                &.col-d:hover {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", -40, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-a.selected,
                &.col-c.selected {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", -20, -20);
                  width: 20px;
                  height: 20px;
                }
                &.col-a:hover,
                &.col-c:hover {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", -40, -20);
                  width: 20px;
                  height: 20px;
                }
              }

              span.seat.row-6,
              span.seat.row-8 {
                &.col-b.unavailable,
                &.col-d.unavailable {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", -40, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-a.unavailable,
                &.col-c.unavailable {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", -40, -20);
                  width: 20px;
                  height: 20px;
                }
              }

              a.seat.row-6,
              a.seat.row-8 {
                &.col-b.selected,
                &.col-d.selected {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", -20, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-b:hover,
                &.col-d:hover {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", 0, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-a.selected,
                &.col-c.selected {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", -20, -20);
                  width: 20px;
                  height: 20px;
                }
                &.col-a:hover,
                &.col-c:hover {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", 0, -20);
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }

      /* Hilyo's Holiday Express layout with facing seats in rows 1-2 and 9-10 */
      @for $station_id from 1 through 2 {
        &.departure-station-#{$station_id} {
          li.car.rows-10hhe {
            div.seatmap {

              /* noteIcon overlay */
              .seat {
                span.noteIcon {
                  position: absolute;
                  font-weight: 300;
                  font-size: 12px; /* use pixels, not em. */
                  line-height: 12px;
                  color: #999999;
                }

                &.col-a,
                &.col-c {
                  span.noteIcon {
                    top: 2px;
                  }
                }

                &.col-b,
                &.col-d {
                  span.noteIcon {
                    top: 7px;
                  }
                }

                &.selected {
                  span.noteIcon {
                    color: #AAAAAA;
                  }
                }

                &:hover {
                  span.noteIcon {
                    color: #CCCCCC;
                  }
                }
              }

              .seat.row-1,
              .seat.row-9 {
                span.noteIcon {
                  left: 7px;
                }
                span.poorSightLinesIcon {
                  left: 7px;
                }
              }
              .seat.row-2,
              .seat.row-10 {
                span.noteIcon {
                  left: 3px;
                }
                span.poorSightLinesIcon {
                  left: 2px;
                  }
              }

              /* seat selection states */
              span.seat.row-1,
              span.seat.row-9 {
                &.col-b.unavailable,
                &.col-d.unavailable {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", 0, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-a.unavailable,
                &.col-c.unavailable {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", 0, -20);
                  width: 20px;
                  height: 20px;
                }
              }

              a.seat.row-1,
              a.seat.row-9 {
                &.col-b.selected,
                &.col-d.selected {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", -20, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-b:hover,
                &.col-d:hover {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", -40, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-a.selected,
                &.col-c.selected {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", -20, -20);
                  width: 20px;
                  height: 20px;
                }
                &.col-a:hover,
                &.col-c:hover {
                  @include retina-sprite($seatmaps, "BenchStatesReturn", -40, -20);
                  width: 20px;
                  height: 20px;
                }
              }

              span.seat.row-2,
              span.seat.row-10 {
                &.col-b.unavailable,
                &.col-d.unavailable {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", -40, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-a.unavailable,
                &.col-c.unavailable {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", -40, -20);
                  width: 20px;
                  height: 20px;
                }
              }

              a.seat.row-2,
              a.seat.row-10 {
                &.col-b.selected,
                &.col-d.selected {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", -20, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-b:hover,
                &.col-d:hover {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", 0, 0);
                  width: 20px;
                  height: 20px;
                }
                &.col-a.selected,
                &.col-c.selected {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", -20, -20);
                  width: 20px;
                  height: 20px;
                }
                &.col-a:hover,
                &.col-c:hover {
                  @include retina-sprite($seatmaps, "BenchStatesOutbound", 0, -20);
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }

      /* poor sight lines icon */
      @for $station_id from 1 through 2 {
        &.departure-station-#{$station_id} {
          li.car {
            div.seatmap {
              /* poorSightLinesIcon overlay */
              .seat {
                span.poorSightLinesIcon {
                  position: absolute;
                  font-weight: 300;
                  font-size: 12px; /* use pixels, not em. */
                  line-height: 12px;
                  color: #999999;
                }

                &.col-a,
                &.col-c {
                  span.poorSightLinesIcon {
                    top: 2px;
                  }
                }

                &.col-b,
                &.col-d {
                  span.poorSightLinesIcon {
                    top: 7px;
                  }
                }

                &.selected {
                  span.poorSightLinesIcon {
                    color: #AAAAAA;
                  }
                }

                &:hover {
                  span.poorSightLinesIcon {
                    color: #CCCCCC;
                  }
                }
              }
            }
          }
        }
      }
      &.departure-station-1 {
        li.car {
          div.seatmap {
            .seat {
              span.poorSightLinesIcon {
                left: 2px;
              }
            }
          }
        }
      }
      &.departure-station-2 {
        li.car {
          div.seatmap {
            .seat {
              span.poorSightLinesIcon {
                left: 7px;
              }
            }
          }
        }
      }


      /* hack to fix Red Fern seat pixels off by 1 */
      @for $station_id from 1 through 2 {
        &.departure-station-#{$station_id} {
          li.car.rows-16, li.car.rows-17rf {
            div.seatmap {
              .seat {
                $row_start: 33px;
                @for $i from 1 through $max_rows {
                  &.row-#{$i} {
                    left: calc(#{$row_start} + (#{$i} * (#{$row_offset} + #{$seat_size}))) !important;
                  }
                }
              }
            }
          }
        }
      }


    }
  }
}
