
$base-font-size: 12px;
$rem-base: 12px;
// $body-bg: #fff !default;
// $body-font-color: #222 !default;

$body-font-family: "Arvo", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;

// generated color schema variables
$light-faint-primary: #e9e0e0;

$color-primary-0: #953220;  // Main Primary color */
$color-primary-1: #E48979;
$color-primary-2: #B85442;
$color-primary-3: #6C1606; // darker +1 - very close to 6D1606
$color-primary-4: #430A00;

$color-secondary-1-0: #174C5D;  // Main Secondary color (1) */
$color-secondary-1-1: #4E7E8F;
$color-secondary-1-2: #2C6173;
$color-secondary-1-3: #063444;
$color-secondary-1-4: #01202A;

$color-secondary-2-0: #5B881D;  // Main Secondary color (2) */
$color-secondary-2-1: #A7D16E;
$color-secondary-2-2: #7BA93C;
$color-secondary-2-3: #3C6306;
$color-secondary-2-4: #243E00;

// As RGBa codes */

$rgba-primary-0: rgba(149, 50, 32,1);   // Main Primary color */
$rgba-primary-1: rgba(228,137,121,1);
$rgba-primary-2: rgba(184, 84, 66,1);
$rgba-primary-3: rgba(108, 22,  6,1);
$rgba-primary-4: rgba( 67, 10,  0,1);

$rgba-secondary-1-0: rgba( 23, 76, 93,1);   // Main Secondary color (1) */
$rgba-secondary-1-1: rgba( 78,126,143,1);
$rgba-secondary-1-2: rgba( 44, 97,115,1);
$rgba-secondary-1-3: rgba(  6, 52, 68,1);
$rgba-secondary-1-4: rgba(  1, 32, 42,1);

$rgba-secondary-2-0: rgba( 91,136, 29,1);   // Main Secondary color (2) */
$rgba-secondary-2-1: rgba(167,209,110,1);
$rgba-secondary-2-2: rgba(123,169, 60,1);
$rgba-secondary-2-3: rgba( 60, 99,  6,1);
$rgba-secondary-2-4: rgba( 36, 62,  0,1);


// $replaced the Foundation colors
$primary-color: $color-secondary-1-0 !default;   // darker blue
$secondary-color: #e7e7e7 !default; // white-lilac
$alert-color: #f04124 !default;     // cinnabar
$success-color: $color-secondary-2-0 !default;   // green
$warning-color: #f08a24 !default;   // carrot
$info-color: $color-secondary-1-2 !default;      // lighter blue

// divergent category colors
$category0: #377eb8;
$category1: #4daf4a;
$category2: #984ea3;
$category3: #ff7f00;
$category4: #e41a1c;

@import "node_modules/zurb-foundation-5/scss/foundation/_functions";
$h4-font-size: rem-calc(18);
$table-row-font-size: rem-calc(11);
$table-row-padding: rem-calc(1 2);
$reveal-position-top: rem-calc(20); /* put very close to the top for mobile phones */

$table-bg: inherit;
$table-foot-font-weight: normal;

@import "node_modules/zurb-foundation-5/scss/foundation";

#seat-picker-modal { // sass-lint:disable-line no-ids
  .train-carlist-container {
    .train-description-return {
      top: 175px; /* due to smaller font on the backend, adjust this up a few pixels. */
    }
  }
}

.oneline {
  white-space: nowrap;
}
.money-due {
  background: $warning-color;
  color: $white;
  font-weight: bold;
}
.checkedin {
  background: $color-secondary-2-2;
  color: $white;
}
.released {
  background: $warning-color;
  $color: white;
}
.cancelled {
  background: $alert-color;
  color: $white;
  font-weight: bold;
}
.item, .item0 {
    background-color: $light-faint-primary;
}
.blocked {
  background: #666666;
  font-weight: bold;
  color: #333333;
}
.header {
  color: $light-faint-primary;
  background: linear-gradient(to bottom, #9e4428 0%, #6d1606 99%);
  font-weight:bold;
}

input {
  .dont-expand {
    width: auto !important;
    display: inline !important;
  }
}

#car-lineup-blade {
  .cars {
    margin: 0 auto;
    padding: rem-calc(6);
    min-height: rem-calc(108);
    width: 460px;
    background: #dddddd;
  }
  .tile {
    width: 440px;
    height: rem-calc(96);
    margin: rem-calc(6) auto;
    border: 1px solid #333333;
    border-radius: 4px;
  }
  .placeholder {
    width: 440px;
    height: rem-calc(96);
    border: 1px dashed #666666;
    margin: rem-calc(6) auto;
    border-radius: 4px;
    background: #ffffcc;
  }
}

table.checkin {
  td {
    border: 1px solid #cccccc;
    line-height: 1.2rem;
    input[type=checkbox], input[type=radio] {
      margin-bottom: 0.1rem;
    }
  }
}

.selectable-row {
  &:hover {
    // -webkit-box-shadow: inset 2px 1px 12px 2px rgba(  6, 52, 68, 0.3);
    // box-shadow: inset 2px 1px 12px 2px rgba(  6, 52, 68, 0.3);
    background: $color-secondary-1-1;
    td, div, article {
      &:not(.actions) {
        background: $color-secondary-1-1;
      }
    }
  }
  &.preselected {
    background: $color-secondary-2-3;
    td {
      color: $light-faint-primary;
    }
  }
  &.chosen {
    background: $color-primary-1 !important;
  }
  &.chosen-secondary {
    background-color: $color-primary-2 !important;
  }
}

// divergent category colors

.category0 {
  background: $category0;
  color: $white;
}
.category1 {
  background: $category1;
  color: $white;
}
.category2 {
  background: $category2;
  color: $white;
}
.category3 {
  background: $category3;
  color: $white;
}
.category4 {
  background: $category4;
  color: $white;
}

.text-category0 {
  color: $category0;
}
.text-category1 {
  color: $category1;
}
.text-category2 {
  color: $category2;
}
.text-category3 {
  color: $category3;
}
.text-category4 {
  color: $category4;
}

/*
NOTES:
things that need color
today's train
today's return train - slightly off color
checked in resv
cancelled resv
relased resv
hover
clicked-on row
errors, warnings
needs payment
has a comment?
is one-way?

alternating rows needs to be more than gray and white?
levels of headers? there's this thing called 'itemheader'?

need left-borders on all cells except first? or all cells?

merging or overwriting rules

alternating rows bg is lowest priority
the state of existing is third highest
preselected is second-highest - exceptions: status colors of a single cell like balance due or paid up, or error states
hover is highest priority



*/

.daily-report-content {
  .report-header {
    display: none;
  }
  .top-page {
    .report-header {
      display: block;
      border-bottom: 2px dotted #333;
    }
  }
}


// $color-primary-0: #6D1606;  // Main Primary color */
@page {
  margin: 2cm 1cm;
}

@media print {
  .daily-report-content {
    .report-header {
      display: block;
      border-bottom: 2px dotted #333;
    }
    .new-page {
      page-break-before: always;
    }
    .own-page {
      page-break-before: always;
      page-break-after: always;
    }
    a {
      text-decoration: none;
    }
    a:after{
      display: none;
    }

    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 14px;
    }
    h3 {
      font-size: 12px;
    }
    h4 {
      font-size: 12px;
    }
    p {
      font-size: 10px;
    }

    table {
      border: 2px solid #333;
      td {
        border: 1px solid #ddd;
        font-size: 10px;
      }
      th {
        border: 1px double #333;
        font-size: 10px;
      }
    }
  }
  .report-style {
    a {
      text-decoration: none;
    }
    a:after{
      display: none;
    }
    table {
      border: 2px solid #333;
      td {
        border: 1px solid #ddd;
        font-size: 10px;
      }
      th {
        border: 1px double #333;
        font-size: 10px;
      }
    }
  }
}

